"use client";

import {
  useIdentifyToFullStory,
  useInstallFullStory,
} from "@zapier/react-fullstory";
import { Session } from "next-auth";
import { FunctionComponent } from "react";

interface FullStoryProps {
  session: Session | null;
}

export const FullStory: FunctionComponent<FullStoryProps> = ({ session }) => {
  const user = session?.user ?? undefined;

  useInstallFullStory();
  useIdentifyToFullStory({
    id: user?.id ?? "",
    email: user?.email ?? "",
    displayName: user?.name ?? "",
  });

  return null;
};

import { Realtime, RealtimeClient } from "ably";
import { AblyMessageCallback, useChannel } from "ably/react";
import { useEffect, useRef, useState } from "react";

import { AblyEventName, AblyEventType } from "./ably-events";

/**
 * URL to use for Ably authentication.
 * This endpoint returns a token request object that the client will use to get an actual token.
 * https://ably.com/docs/auth/token
 */
const AUTH_URL = "/api/ably-auth";

/**
 * Create a new realtime Ably client.
 * This is intended to be used on the client-side.
 *
 * @param authParams - Params to pass to the `/api/ably-auth` endpoint. Used to augment capabilities for the generated token.
 */
const createAblyRealtimeClient = () =>
  new Realtime({
    authUrl: AUTH_URL,
    authMethod: "POST",
  });

/**
 * Auth params that can be passed to Ably.
 */

/**
 * This hook will wait until we're on the client to actually create the Ably client.
 *
 * If this runs on the server, it will return null.
 * When this returns null, you'll have to render a fallback component.
 *
 * @param authParams - Params to pass to the `/api/ably-auth` endpoint. Used to augment capabilities for the generated token.
 */
export const useAblyRealtimeClient = (skipAbly: boolean) => {
  const [client, setClient] = useState<RealtimeClient | null>(null);
  const windowIsDefined = typeof window !== "undefined";
  const hasOpenConnection = useRef(false);
  useEffect(() => {
    if (windowIsDefined && !client && !hasOpenConnection.current && !skipAbly) {
      setClient(createAblyRealtimeClient());
      hasOpenConnection.current = true;
    }
  }, [windowIsDefined, client, skipAbly]);

  // todo: see if we can have 3par broadcast the message to userId channel(s) instead.
  // would make it so we don't have to reauth when adding a new integration
  return client;
};

/**
 * Wrapper around Ably's `useChannel` that includes specific types for each even name.
 *
 * @param channelId - ID of channel to listen to
 * @param eventName - Name of event to listen to
 * @param callbackOnMessage - Callback for when a message is received
 */
export const useAblyChannel = <T extends AblyEventName>(
  channelId: string,
  eventName: T,
  callbackOnMessage: (
    message: Omit<AblyMessageCallback, "data"> & { data: AblyEventType[T] },
  ) => void,
) => {
  return useChannel(
    {
      channelName: channelId,
      onConnectionError: (error) => {
        console.error("Ably connection error", error);
      },
      onChannelError(error) {
        console.error("Ably channel error", error);
      },
    },
    eventName,
    callbackOnMessage,
  );
};

"use client";

import { Colors } from "@zapier/design-system";
import NextTopLoader from "nextjs-toploader";

export const TopLoader = () => {
  return (
    <NextTopLoader
      color={Colors.zapier}
      showSpinner={false}
      zIndex={9998}
      template={`<div class="bar" role="bar"><div class="peg"></div></div>
  <div class="spinner" role="spinner"><div class="spinner-icon"></div></div>`}
    />
  );
};

"use client";

import { RumInitConfiguration, datadogRum } from "@datadog/browser-rum";
import { PropsWithChildren, useEffect, useState } from "react";

import { Theme, ThemeContext } from "@/context/theme";
import {
  ALLOWED_TRACING_ORIGINS,
  SERVICE_APP_NAME,
  getEnvironment,
} from "@/lib/environment";

const allowedTracingUrls = ALLOWED_TRACING_ORIGINS.map((origin) => ({
  match: origin,
  propagatorTypes: ["tracecontext", "datadog"],
})) as NonNullable<RumInitConfiguration["allowedTracingUrls"]>;

function initializeDatadogRum() {
  if (typeof window === "undefined") {
    return;
  }
  if (
    process.env.NODE_ENV === "production" &&
    process.env.DATADOG_APPLICATION_ID &&
    process.env.DATADOG_CLIENT_TOKEN
  ) {
    datadogRum.init({
      applicationId: process.env.DATADOG_APPLICATION_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: SERVICE_APP_NAME,
      env: getEnvironment(),
      version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [
        {
          match: window.origin,
          propagatorTypes: ["tracecontext", "datadog"],
        },
        ...allowedTracingUrls,
      ],
    });
  }
}

initializeDatadogRum();

export const DocumentRoot = ({ children }: PropsWithChildren) => {
  const [theme, setTheme] = useState<Theme>("light");
  const [signedInFromExtension, setSignedInFromExtension] = useState<boolean>();

  useEffect(() => {
    // handle theme
    const urlParamTheme = new URLSearchParams(window.location.search).get(
      "theme",
    );

    if (urlParamTheme === "dark") {
      setTheme("dark");
    }

    // handle if signed in from extension
    const signedInFromExtensionQueryParam = new URLSearchParams(
      window.location.search,
    ).get("signedInFromExtension");

    setSignedInFromExtension(signedInFromExtensionQueryParam === "true");

    // if this has been launched from the extension sign in flow, close the window
    // if we are at this point, that means the sign in has succeeded
    if (signedInFromExtensionQueryParam === "true") {
      window.close();
    }
  }, []);

  // if we haven't looked up query params yet, don't render anything
  // this is to avoid a "flash" of the Central UI while signing in from the extension
  if (signedInFromExtension === undefined) {
    return (
      <ThemeContext.Provider value={{ theme, setTheme: setTheme }}>
        <html lang="en" className={theme}>
          <body />
        </html>
      </ThemeContext.Provider>
    );
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme: setTheme }}>
      <html lang="en" className={theme}>
        {children}
      </html>
    </ThemeContext.Provider>
  );
};

"use client";

/* eslint-disable import/no-named-as-default-member -- We need to load the whole mixpanel due to https://github.com/mixpanel/mixpanel-js/issues/321 */
import mixpanel from "mixpanel-browser";
import { Session } from "next-auth";
import { FunctionComponent } from "react";

interface MixpanelBrowserProps {
  session: Session | null;
}

export const MixpanelBrowser: FunctionComponent<MixpanelBrowserProps> = ({
  session,
}) => {
  const user = session?.user ?? undefined;
  const token = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN;
  const isDebugMode = process.env.NEXT_PUBLIC_VERCEL_ENV !== "production";

  if (user && token) {
    mixpanel.init(token, {
      debug: isDebugMode,
      persistence: "localStorage",
    });

    // We dont need to set name or email, because it has already been set in the server
    mixpanel.identify(user?.id);
  }

  return null;
};

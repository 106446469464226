"use client";

import { RealtimeClient } from "ably";
import { FC, ReactNode, createContext, useContext } from "react";

import { useAblyRealtimeClient } from "@/lib/ably-client";

const AblyClientContext = createContext<RealtimeClient | null>(null);

export const useAblyClient = () => {
  return useContext(AblyClientContext);
};

interface AblyClientProviderProps {
  children: ReactNode;
  skipAbly: boolean;
}

/**
 * Provider for the Ably client.
 * We use this in Providers.tsx to reuse the same Ably client across the app.
 *
 * When consuming, make sure you have the client passed into an `<AblyProvider>` above
 * where you're consuming from.
 */
export const AblyClientProvider: FC<AblyClientProviderProps> = ({
  children,
  skipAbly,
}) => {
  const ablyClient = useAblyRealtimeClient(skipAbly);

  return (
    <AblyClientContext.Provider value={ablyClient}>
      {children}
    </AblyClientContext.Provider>
  );
};

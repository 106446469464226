import { StreamEventData, WorkflowWithLiftedData } from "@zapai/client-sdk";

import { Integration } from "@/hooks/three-par";

/**
 * @param chatId - ID of chat
 * @returns ID of channel to use when connecting to Ably
 */
export const channelIdForChat = (chatId: string) => `chat:${chatId}`;
export const channelIdForUserIntegration = (
  currentAccountId: number,
  zapierCustomUserId: number,
) => `integration:account:${currentAccountId}:customuser:${zapierCustomUserId}`;
export const channelIdForSharedIntegration = (currentAccountId: number) =>
  `integration:account:${currentAccountId}`;
export const channelIdForBot = (botId: string) => `bot:${botId}`;

export enum AblyEventName {
  /** Streaming chat message */
  StreamingEvent = "STREAMING_EVENT",

  /** Sent when chat messages are cleared */
  ChatMessagesCleared = "CHAT_MESSAGES_CLEARED",

  /** Sent from 3par when a data source integration has an updated status */
  IntegrationStatusUpdate = "THREE_PAR_INTEGRATION_UPDATE",

  /** Sent when a workflow updates in response to a trigger subscription success or failure */
  WorkflowUpdate = "WORKFLOW_UPDATE",
}

/**
 * Mapping of event name to event data type
 */
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions -- We want a type here and not an interface
export type AblyEventType = {
  [AblyEventName.StreamingEvent]: StreamEventData;
  [AblyEventName.ChatMessagesCleared]: Record<string, never>;
  [AblyEventName.IntegrationStatusUpdate]: Integration;
  [AblyEventName.WorkflowUpdate]: WorkflowWithLiftedData;
};

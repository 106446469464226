"use client";

import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/nextjs";
import { Session } from "next-auth";
import { useEffect } from "react";

export const UserInfoTracker = ({ session }: { session: Session | null }) => {
  useEffect(() => {
    if (session?.user?.id) {
      Sentry.setUser({
        id: session.user.id,
        email: session.user.email as string,
      });

      datadogRum.setUser({
        id: session.user.id,
        name: session.user.name ?? undefined,
        email: session.user.email ?? undefined,
      });
    }
  }, [session?.user?.id, session?.user?.email, session?.user?.name]);

  return null;
};

"use client";

import { emit } from "@zapier/events-toolkit-browser";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

import { isProduction } from "../environment";
import { isDatadog } from "./utils";

// Originally from https://gitlab.com/zapier/zapai/bot-templates/-/merge_requests/156,
// which modifies https://gitlab.com/zapier/nextjs-template/-/blob/main/%7B%7Bcookiecutter.gitlab_repo%7D%7D/packages/service/src/observability/tracking/TrackRouting.tsx#L14
// to use usePathname instead of useRouter (since we're using app router instead of pages router).
// Further modified to use only events-toolkit-browser and not the toolbox-tracking package.
export function TrackRouting() {
  const pathname = usePathname();

  useEffect(() => {
    // Only track on the client side
    // and do not track the pre-hydrated path that doesn't include the
    // real params (i.e. [accountId])
    if (
      typeof window !== "undefined" &&
      !(pathname.includes("[") && pathname.includes("]")) &&
      !isDatadog() &&
      isProduction
    ) {
      // We only need to specify the system name that should be used for the Avro PageViewEvent
      // The other PageViewEvent fields are set by automatically by events-toolkit-browser and the v4 Events API
      // https://engineering.zapier.com/guides/event-streaming/tracking-events/
      emit(
        {
          system_name: "central",
        },
        "web_analytics.tracking.page_view.PageViewEvent",
      );
    }
  }, [pathname]);

  return null;
}

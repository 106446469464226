"use client";

import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { AblyProvider, ChannelProvider } from "ably/react";
import { useSession } from "next-auth/react";

import { Integration } from "@/hooks/three-par";
import { useAblyChannel } from "@/lib/ably-client";
import {
  AblyEventName,
  channelIdForUserIntegration, // channelIdForSharedIntegration, (temporarily disabled)
} from "@/lib/ably-events";

import { useAblyClient } from "./ably-client-provider";

const augmentIntegration = (
  integration: Integration,
  currentAccountId: number,
  zapierCustomUserId: number,
) => {
  return {
    ...integration,
    is_owner:
      integration.zapier_account_id === `${currentAccountId}` &&
      integration.customuser_id === `${zapierCustomUserId}`,
  };
};

const AblyIntegrationListener = ({
  queryClient,
  channelName,
  currentAccountId,
  zapierCustomUserId,
}: {
  queryClient: QueryClient;
  channelName: string;
  currentAccountId: number;
  zapierCustomUserId: number;
}) => {
  useAblyChannel(
    channelName,
    AblyEventName.IntegrationStatusUpdate,
    ({ data: integration }) => {
      queryClient.setQueryData<Integration[]>(
        ["three-par-integrations"],
        (existingIntegrations) => {
          const newIntegration = augmentIntegration(
            integration,
            currentAccountId,
            zapierCustomUserId,
          );
          if (!existingIntegrations) {
            return [newIntegration];
          }

          let integrationAlreadyExists = false;
          const updatedIntegrations = existingIntegrations.map(
            (existingIntegration) => {
              if (existingIntegration.id === integration.id) {
                integrationAlreadyExists = true;
                return newIntegration;
              }
              return existingIntegration;
            },
          );

          return integrationAlreadyExists
            ? updatedIntegrations
            : [...updatedIntegrations, newIntegration];
        },
      );
    },
  );

  return null;
};

export const AblyIntegrationListeners = () => {
  const ablyClient = useAblyClient();
  const queryClient = useQueryClient();
  const { data: session } = useSession();
  const user = session?.user;
  const currentAccountId = user?.currentAccountId;
  const zapierCustomUserId = user?.zapierCustomUserId;

  if (!ablyClient || !currentAccountId || !zapierCustomUserId) {
    return null;
  }

  const channelId = channelIdForUserIntegration(
    currentAccountId,
    zapierCustomUserId,
  );

  return (
    <AblyProvider client={ablyClient}>
      <ChannelProvider channelName={channelId}>
        {/* Non-shared integrations */}
        <AblyIntegrationListener
          queryClient={queryClient}
          channelName={channelIdForUserIntegration(
            currentAccountId,
            zapierCustomUserId,
          )}
          zapierCustomUserId={zapierCustomUserId}
          currentAccountId={currentAccountId}
        />
        {/* Shared integrations, temporarily disabled
      <AblyIntegrationListener
        queryClient={queryClient}
        channelName={channelIdForSharedIntegration(currentAccountId)}
        zapierCustomUserId={zapierCustomUserId}
        currentAccountId={currentAccountId}
      /> */}
      </ChannelProvider>
    </AblyProvider>
  );
};
